// src/DateRangePicker.js

import React from "react";

function DateRangePicker({ startDate, endDate, onDateChange }) {
  const formatDate = (date) => date.toISOString().split("T")[0];

  return (
    <div>
      <h2>Select Date Range</h2>
      <label>
        Start Date:
        <input
          type="date"
          value={formatDate(startDate)}
          onChange={(e) => onDateChange("startDate", new Date(e.target.value))}
        />
      </label>
      <br />
      <label>
        End Date:
        <input
          type="date"
          value={formatDate(endDate)}
          onChange={(e) => onDateChange("endDate", new Date(e.target.value))}
        />
      </label>
    </div>
  );
}

export default DateRangePicker;
