// FileUpload.js
import React, { useRef } from "react";

function FileUpload({ onUpload }) {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Read the file content
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        onUpload(content);

        // Reset the file input's value
        fileInputRef.current.value = "";
      };
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".htm,.html"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
    </div>
  );
}

export default FileUpload;
