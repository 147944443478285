// import the app.css file
import styles from "./App.module.css";

// src/App.js
import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import FileUpload from "./FileUpload";
import TimetableGrid from "./TimetableGrid";
import DateRangePicker from "./DateRangePicker";
import ProcessButton from "./ProcessButton";
import {
  parseHtmlContent,
  processTimetableData,
  extractTeachers,
  generateTimetableHtml,
} from "./utils";
import { saveAs } from "file-saver"; // Import saveAs from file-saver
import Modal from "./Modal"; // Import the Modal component

function App() {
  const [timetableData, setTimetableData] = useState([]);
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);
  const [periodsData, setPeriodsData] = useState([]);
  const [timetableHTML, setTimetableHTML] = useState("");
  const [cyclesData, setCyclesData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // Months are 0-indexed (0 = January)
    let academicYearEnd;

    if (currentMonth < 7) {
      // Before August (Months 0-6)
      academicYearEnd = new Date(currentYear, 7, 1); // 1st Aug of current year
    } else {
      // On or after August (Months 7-11)
      academicYearEnd = new Date(currentYear + 1, 7, 1); // 1st Aug of next year
    }

    return academicYearEnd;
  });

  const [teacherNames, setTeacherNames] = useState([]); // State for teacher names
  const [selectedTeacher, setSelectedTeacher] = useState(""); // State for selected teacher

  // Fetch periods.csv
  const fetchPeriodsData = async () => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/periods.csv`);
      const csvText = await response.text();
      const data = Papa.parse(csvText, { header: true }).data;

      setPeriodsData(data);

      // Initialize timetableData with period names and empty dayData
      const initialTimetableData = data.map((period) => ({
        periodName: period["Period"],
        dayData: Array(10).fill({
          eventName: "",
          eventLocation: "",
          homework: false,
        }),
      }));
      setTimetableData(initialTimetableData);
    } catch (error) {
      console.error("Error fetching periods.csv:", error);
    }
  };

  // Fetch cycles.csv
  const fetchCyclesData = async () => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/cycles.csv`);
      const csvText = await response.text();
      const data = Papa.parse(csvText, { header: true }).data;
      setCyclesData(data);
      console.log("Cycles Data:", data);
    } catch (error) {
      console.error("Error fetching cycles.csv:", error);
    }
  };

  // Fetch all_timetables.htm and extract teacher names
  const fetchTeacherNames = async () => {
    try {
      const response = await fetch(
        `${process.env.PUBLIC_URL}/all_timetables.htm`
      );
      const htmlText = await response.text();
      const names = extractTeachers(htmlText);
      setTeacherNames(names);
      setTimetableHTML(htmlText);
    } catch (error) {
      console.error("Error fetching all_timetables.htm:", error);
    }
  };

  useEffect(() => {
    fetchPeriodsData();
    fetchCyclesData();
    fetchTeacherNames();
  }, []);

  const loadTimetableData = (parsedData) => {
    // Overlay parsedData onto existing timetableData
    const updatedTimetableData = timetableData.map((periodRow) => {
      const matchingPeriod = parsedData.find(
        (p) => p.periodName === periodRow.periodName
      );
      if (matchingPeriod) {
        return {
          ...periodRow,
          dayData: matchingPeriod.dayData,
        };
      } else {
        return periodRow;
      }
    });

    setTimetableData(updatedTimetableData);
  };

  const handleFileUpload = (content) => {
    const names = extractTeachers(content);
    setTeacherNames(names);
    setTimetableHTML(content);

    const parsedData = parseHtmlContent(content, ""); // Pass empty teacherName

    loadTimetableData(parsedData);
  };

  const handleTeacherSelect = async (event) => {
    const teacherName = event.target.value;
    setSelectedTeacher(teacherName);

    if (teacherName === "") {
      // If no teacher is selected, clear the timetable
      return;
    }

    const parsedData = parseHtmlContent(timetableHTML, teacherName);
    loadTimetableData(parsedData);
  };

  const handleTimetableUpdate = (updatedData) => {
    setTimetableData(updatedData);
  };

  const handleDateChange = (type, date) => {
    if (type === "startDate") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleProcess = async () => {
    setIsProcessing(true);
    await processTimetableData(
      timetableData,
      periodsData,
      cyclesData,
      startDate,
      endDate
    );
    setIsProcessing(false);
    setShowInstructionsModal(true); // Show the modal after processing
  };

  const handleCloseModal = () => {
    setShowInstructionsModal(false);
  };

  // Handle Save Grid button click
  const handleSaveGrid = () => {
    const htmlContent = generateTimetableHtml(timetableData);
    const blob = new Blob([htmlContent], { type: "text/html;charset=utf-8" });
    saveAs(blob, "timetable.htm");
  };

  return (
    <div className={styles.App}>
      {/* Render the Modal */}
      {showInstructionsModal && (
        <Modal onClose={handleCloseModal}>
          <h2>Instructions for Outlook Importing</h2>
          <p>
            To import your timetable into Outlook, please follow these steps:
          </p>
          <ol>
            <li>
              Go to{" "}
              <a
                href="https://outlook.office.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                outlook.office.com
              </a>{" "}
              and sign in to your account.
            </li>
            <li>
              Navigate to your calendar and select <strong>Add calendar</strong>{" "}
              from the left hand menu.
            </li>
            <li>
              Choose <strong>Upload from file</strong> and select the downloaded{" "}
              <code>.ics</code> file.
            </li>
            <li>
              Follow the prompts to choose the calendar to import into (usually
              your main calendar) to complete the import.
            </li>
            <li>
              To search for all timetable events for future editing/deleting you
              can use the Outlook search bar to search for{" "}
              <code>[TIMETABLE]</code> or and individual day-period e.g.{" "}
              <code>[P3-4/D6]</code>
            </li>
          </ol>
          <img
            src={`${process.env.PUBLIC_URL}/instructions.gif`}
            alt="Instructions GIF"
            style={{ width: "100%", maxWidth: "600px" }}
          />
        </Modal>
      )}

      <a href="/">
        <div className={styles.header}>&nbsp;</div>
      </a>
      <h1>Teacher Outlook Timetable Generator</h1>
      <div>
        <h2>
          Select a Teacher from the current export of the timetable file to load
          their timetable:
        </h2>
        <select value={selectedTeacher} onChange={handleTeacherSelect}>
          <option value="">-- Select a Teacher --</option>
          {teacherNames.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <h2>
          Or upload a New Timetable File from a SIMS timetable HTML file export.
        </h2>
        <FileUpload onUpload={handleFileUpload} />
      </div>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChange}
      />
      <div>
        <ProcessButton onProcess={handleProcess} isProcessing={isProcessing} />
        <button onClick={handleSaveGrid}>Save Grid for Future Editing</button>
      </div>
      <div>
        <em>
          Click into a cell to make changes; right-click a cell to mark it as
          homework set.
        </em>
      </div>
      <TimetableGrid
        data={timetableData}
        onDataUpdate={handleTimetableUpdate}
      />
    </div>
  );
}

export default App;
