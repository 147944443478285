// src/ProcessButton.js

import React from "react";

function ProcessButton({ onProcess, isProcessing }) {
  return (
    <div>
      <button onClick={onProcess} disabled={isProcessing}>
        {isProcessing ? "Processing..." : "Generate iCal File"}
      </button>
    </div>
  );
}

export default ProcessButton;
