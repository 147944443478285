// src/TimetableGrid.js

import React, { useState } from "react";
import styles from "./App.module.css";

const TimetableGrid = ({ data, onDataUpdate }) => {
  const [editingCell, setEditingCell] = useState(null);

  const handleCellChange = (periodIndex, dayIndex, field, value) => {
    const updatedData = [...data];
    updatedData[periodIndex] = {
      ...updatedData[periodIndex],
      dayData: [...updatedData[periodIndex].dayData],
    };

    // Get the current cell data
    const currentCellData = updatedData[periodIndex].dayData[dayIndex];

    // Update the specific field
    const newCellData = {
      ...currentCellData,
      [field]: value,
    };

    // If the eventName has become empty, reset eventLocation and homework
    if (field === "eventName" && value.trim() === "") {
      newCellData.eventLocation = "";
      newCellData.homework = false;
    }

    updatedData[periodIndex].dayData[dayIndex] = newCellData;
    onDataUpdate(updatedData);
  };

  const handleCellRightClick = (e, periodIndex, dayIndex) => {
    e.preventDefault();
    const updatedData = [...data];
    updatedData[periodIndex] = {
      ...updatedData[periodIndex],
      dayData: [...updatedData[periodIndex].dayData],
    };
    const currentHomeworkStatus =
      updatedData[periodIndex].dayData[dayIndex].homework;
    updatedData[periodIndex].dayData[dayIndex] = {
      ...updatedData[periodIndex].dayData[dayIndex],
      homework:
        !currentHomeworkStatus &&
        updatedData[periodIndex].dayData[dayIndex].eventName !== "",
    };
    onDataUpdate(updatedData);
  };

  const handleCellClick = (periodIndex, dayIndex) => {
    setEditingCell({ periodIndex, dayIndex });
  };

  const handleCellBlur = (e, periodIndex, dayIndex) => {
    // Check if the related target is still within the cell (e.g., the other input)
    if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    setEditingCell(null);
  };

  const dayNames = [
    "AMon",
    "ATue",
    "AWed",
    "AThu",
    "AFri",
    "BMon",
    "BTue",
    "BWed",
    "BThu",
    "BFri",
  ];

  return (
    <table border="1">
      <thead>
        <tr>
          <th>Period</th>
          {dayNames.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map(
          (periodRow, periodIndex) =>
            periodRow.periodName !== "" && (
              <tr key={periodIndex}>
                <td>{periodRow.periodName}</td>
                {periodRow.dayData.map((cellData, dayIndex) => {
                  const isEditing =
                    editingCell &&
                    editingCell.periodIndex === periodIndex &&
                    editingCell.dayIndex === dayIndex;

                  return (
                    <td
                      key={dayIndex}
                      onContextMenu={(e) =>
                        handleCellRightClick(e, periodIndex, dayIndex)
                      }
                      onClick={() => handleCellClick(periodIndex, dayIndex)}
                      className={
                        cellData.homework
                          ? styles.homeworkCell
                          : styles.timetableCell
                      }
                    >
                      {isEditing ? (
                        <div
                          onBlur={(e) =>
                            handleCellBlur(e, periodIndex, dayIndex)
                          }
                          tabIndex={-1} // Make the div focusable to catch blur events
                        >
                          <input
                            type="text"
                            placeholder="Event Name"
                            value={cellData.eventName}
                            onChange={(e) =>
                              handleCellChange(
                                periodIndex,
                                dayIndex,
                                "eventName",
                                e.target.value
                              )
                            }
                            style={{ width: "90%" }}
                            autoFocus
                          />
                          <br />
                          <input
                            type="text"
                            placeholder="Location"
                            value={cellData.eventLocation}
                            onChange={(e) =>
                              handleCellChange(
                                periodIndex,
                                dayIndex,
                                "eventLocation",
                                e.target.value
                              )
                            }
                            style={{ width: "90%" }}
                          />
                        </div>
                      ) : (
                        <>
                          <div>{cellData.eventName || <div>&nbsp;</div>}</div>
                          <div>
                            {cellData.eventLocation || <div>&nbsp;</div>}
                          </div>
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            )
        )}
      </tbody>
    </table>
  );
};

export default TimetableGrid;
