// src/utils.js

// Import statements
import {
  parse,
  format,
  isBefore,
  isAfter,
  isSameDay,
  addDays,
  differenceInDays,
  isWeekend,
  startOfDay,
  endOfDay,
} from "date-fns";

import { saveAs } from "file-saver";

export const extractTeachers = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Find all elements with class 'TitleBold' (teacher names)
  const teacherElements = Array.from(doc.querySelectorAll(".TitleBold"));

  // Extract the text content from each element and trim whitespace
  const teacherNames = teacherElements.map((element) => {
    let name = element.textContent.trim();
    // Remove the "Timetable  - " prefix if it exists
    name = name.replace(/^Timetable\s+-\s+/, "").trim();
    return name;
  });

  return teacherNames;
};

// Function to generate the timetable HTML
export const generateTimetableHtml = (timetableData) => {
  // Start building the HTML string
  let htmlContent = `
<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <title>Timetable</title>
  <style type="text/css">
    .TextNormal {
      font-family: Arial;
      font-weight: normal;
      font-size: 10pt;
      color: black;
    }
    .TableBorder {
      font-family: 'Arial';
      border: black solid;
      font-size: 10pt;
      direction: ltr;
      line-height: normal;
    }
    .BreakBeforeDaysAcrossView {
      font-weight: bold;
      font-size: 10pt;
      color: black;
      font-family: Arial;
      border-top: black 1px solid;
    }
    .BreakAfterDaysAcrossView {
      font-weight: bold;
      font-size: 10pt;
      color: black;
      font-family: Arial;
      border-bottom: black 1px solid;
    }
    .BreakBeforePeriodsAcrossView {
      font-weight: bold;
      font-size: 10pt;
      color: black;
      font-family: Arial;
      border-left: black 1px solid;
    }
    .BreakAfterPeriodsAcrossView {
      font-weight: bold;
      font-size: 10pt;
      color: black;
      font-family: Arial;
      border-right: black 1px solid;
    }
    .NoBorder {
      font-weight: bold;
      font-size: 10pt;
      color: black;
      font-family: Arial;
      border: black thin ;
    }
    .TitleBold {
      font-weight: bold;
      font-size: 15pt;
      color: black;
      font-family: Arial;
      border: black thin solid;
      border-width: 0px;
    }
  </style>
</head>
<body>
  <table cellspacing="0" cellpadding="0">
    <tr>
      <td class="TextNormal"></td>
    </tr>
    <tr>
      <td class="TitleBold" align="center">Timetable</td>
    </tr>
    <tr>
      <td class="TextNormal" align="center">as at ${new Date().toLocaleDateString()}</td>
    </tr>
    <tr height="10px">
      <td align="center"></td>
    </tr>
    <tr>
      <td>
        <table cellspacing="0" cellpadding="0">
`;

  // Add the header row with day names
  htmlContent += '          <tr align="center">\n';
  htmlContent += '            <td width="30px" class="NoBorder"></td>\n';

  const dayHeaders = [
    "AMon",
    "ATue",
    "AWed",
    "AThu",
    "AFri",
    "BMon",
    "BTue",
    "BWed",
    "BThu",
    "BFri",
  ];
  dayHeaders.forEach((day) => {
    htmlContent += `            <td width="100px" class="NoBorder">${day}</td>\n`;
  });
  htmlContent += "          </tr>\n";

  // Now, add the rows for each period
  timetableData.forEach((periodRow) => {
    htmlContent += '          <tr align="center">\n';
    htmlContent += `            <td class="NoBorder">${periodRow.periodName}</td>\n`;

    periodRow.dayData.forEach((cellData) => {
      htmlContent += "            <td>\n";
      htmlContent += "<table>";
      if (cellData.eventName) {
        htmlContent += `<tr><td>${cellData.eventName}</td></tr>`;
        htmlContent += `<tr><td>`;
        htmlContent += cellData.eventLocation
          ? `${cellData.eventLocation}`
          : `&nbsp;`;
        htmlContent += `</td></tr>`;
        htmlContent += `<tr><td>`;
        htmlContent += cellData.homework ? `[HWK]` : `&nbsp;`;
        htmlContent += `</td></tr>`;
      }
      htmlContent += "</table>";
      htmlContent += "</td>\n";
    });

    htmlContent += "          </tr>\n";
  });

  // Close the tables and body
  htmlContent += `
        </table>
      </td>
    </tr>
  </table>
</body>
</html>
`;

  return htmlContent;
};

export const parseHtmlContent = (htmlString, teacherName) => {
  if (!teacherName) {
    // find first one
    teacherName = "Timetable";
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Find all elements with class 'TitleBold' (teacher names)
  const teacherElements = Array.from(doc.querySelectorAll(".TitleBold"));

  let timetableTable = null;

  for (let i = 0; i < teacherElements.length; i++) {
    const teacherElement = teacherElements[i];
    const name = teacherElement.textContent.trim();

    if (name.includes(teacherName)) {
      // Found the teacher's section
      // The timetable is in the next table after some nodes
      let nextSibling = teacherElement.parentElement.nextElementSibling;

      // find the next row which contains a cell which contains a nested table
      do {
        // check if the row nextSibling contains a nested table using querySelector
        if (nextSibling && nextSibling.querySelector("table")) {
          timetableTable = nextSibling.querySelector("table");
          break;
        }
        nextSibling = nextSibling.nextElementSibling;
      } while (nextSibling);

      if (timetableTable) {
        break;
      }
    }
  }

  if (!timetableTable) {
    console.error(`Timetable for ${teacherName} not found in the HTML file.`);
    return [];
  }

  // Now process the timetable table as before, selecting only immediate child rows from current scope
  const rows = Array.from(
    timetableTable.querySelectorAll(":scope > tbody > tr")
  );
  const timetableData = [];

  // Assume the first row contains day headers
  const headerRow = rows[0];
  const headerCells = Array.from(headerRow.querySelectorAll(":scope > td"));
  const headers = headerCells.map((td) => td.textContent.trim());

  // Find the indices of the day columns (assuming they are labeled like 'AMon', 'ATue', etc.)
  const dayIndices = [];
  headers.forEach((header, index) => {
    if (header.match(/^(A|B)(Mon|Tue|Wed|Thu|Fri|Sat|Sun)$/i)) {
      dayIndices.push(index);
    }
  });

  // Start parsing from the second row
  for (let i = 1; i < rows.length; i++) {
    const row = rows[i];
    const cells = Array.from(row.querySelectorAll(":scope > td"));

    if (cells.length >= dayIndices.length + 1) {
      const periodName = cells[0].textContent.trim();
      const dayData = dayIndices.map((dayIndex) => {
        const cell = cells[dayIndex];
        const cellContent = [];
        const tds = cell.querySelectorAll("td");

        if (tds.length > 0) {
          // If there are nested td elements, extract their text
          tds.forEach((td) => {
            const text = td.textContent.trim().replace(/\u00A0/g, "");
            if (text) {
              cellContent.push(text);
            }
          });
        } else {
          // If there are no nested td elements, extract text directly from the cell
          const text = cell.textContent.trim().replace(/\u00A0/g, "");
          if (text) {
            cellContent.push(text);
          }
        }

        return {
          eventName: cellContent[0] || "",
          eventLocation: cellContent[1] || "",
          homework:
            cellContent[2] && cellContent[2].includes("[HWK]") ? true : false,
        };
      });
      timetableData.push({
        periodName,
        dayData,
      });
    }
  }

  return timetableData;
};

export const getActiveDates = (cyclesData, startDate, endDate) => {
  const activeDates = [];

  let currentDate = startOfDay(startDate);
  while (!isAfter(currentDate, endOfDay(endDate))) {
    // Skip weekends
    if (!isWeekend(currentDate)) {
      let matchedCycle = null;

      // Find the cycle that currentDate falls into
      for (let i = 0; i < cyclesData.length; i++) {
        const cycle = cyclesData[i];
        const cycleStartDate = parse(
          cycle["Cycle Start"],
          "dd/MM/yyyy",
          new Date()
        );

        // if invalid date then continue
        if (isNaN(cycleStartDate)) {
          continue;
        }

        const start_day = parseInt(cycle["Start Day"], 10);
        const end_day = parseInt(cycle["End Day"], 10);

        // Determine cycleEndDate
        const cycleEndDate = addDays(
          cycleStartDate,
          end_day - start_day + (start_day <= 5 && end_day >= 6 ? 2 : 0)
        );

        // Check if currentDate falls within this cycle
        if (
          !isBefore(currentDate, cycleStartDate) &&
          !isAfter(currentDate, cycleEndDate)
        ) {
          matchedCycle = cycle;
          break;
        }
      }

      if (matchedCycle) {
        const cycleStartDate = parse(
          matchedCycle["Cycle Start"],
          "dd/MM/yyyy",
          new Date()
        );
        const start_day = parseInt(matchedCycle["Start Day"], 10);
        // Calculate dayNumber based on the date difference between cycleStartDate and currentDate
        // first find number of days between cycleStartDate and currentDate
        const dateDiff = differenceInDays(currentDate, cycleStartDate);
        let dayNumber = start_day + dateDiff;
        // correct if weekend inbetween
        if (start_day + dateDiff > 5) {
          dayNumber -= 2;
        }

        activeDates.push({
          date: currentDate,
          dayNumber: dayNumber,
        });
      }
    }

    currentDate = addDays(currentDate, 1);
  }

  return activeDates;
};

export const processTimetableData = async (
  timetableData,
  periodsData,
  cyclesData,
  startDate,
  endDate
) => {
  const timeZone = "Europe/London"; // Set your desired time zone

  // Map cycles to dates using the updated getActiveDates function
  const activeDates = getActiveDates(cyclesData, startDate, endDate);

  // Create a map of day numbers to dates
  const dayNumberToDatesMap = {};
  activeDates.forEach(({ date, dayNumber }) => {
    if (!dayNumberToDatesMap[dayNumber]) {
      dayNumberToDatesMap[dayNumber] = [];
    }
    dayNumberToDatesMap[dayNumber].push(date);
  });

  // Initialize the iCal content
  let icalContent = "";
  icalContent += "BEGIN:VCALENDAR\r\n";
  icalContent += "VERSION:2.0\r\n";
  icalContent += "PRODID:-//Your Organization//Your Product//EN\r\n";
  icalContent += "CALSCALE:GREGORIAN\r\n";
  icalContent += "METHOD:PUBLISH\r\n";

  // Include VTIMEZONE component
  icalContent += generateVTimezoneComponent(timeZone);

  // Process each day number (1 to 10)
  for (let dayNumber = 1; dayNumber <= 10; dayNumber++) {
    const datesForDay = dayNumberToDatesMap[dayNumber];
    if (!datesForDay) continue;
    const dayIndex = dayNumber - 1; // Adjust for zero-based index

    // Process each period
    for (
      let periodIndex = 0;
      periodIndex < timetableData.length;
      periodIndex++
    ) {
      let periodData = timetableData[periodIndex];
      const periodInfo = periodsData.find(
        (p) => p["Period"] === periodData.periodName
      );
      if (!periodInfo) continue;

      let cellData = periodData.dayData[dayIndex];
      if (!cellData || !cellData.eventName) continue;

      // Start merging periods with same eventName and eventLocation
      let startPeriodIndex = periodIndex;
      let endPeriodIndex = periodIndex;

      for (
        let nextIndex = periodIndex + 1;
        nextIndex < timetableData.length;
        nextIndex++
      ) {
        let nextPeriodData = timetableData[nextIndex];
        let nextCellData = nextPeriodData.dayData[dayIndex];
        if (
          nextCellData &&
          nextCellData.eventName === cellData.eventName &&
          nextCellData.eventLocation === cellData.eventLocation
        ) {
          endPeriodIndex = nextIndex;
        } else {
          break;
        }
      }

      // Collect all dates for this day number
      let eventDates = datesForDay.map((date) => date);

      // Build exclude dates (dates when the event doesn't occur)
      // Generate all possible dates from first event date to end date, every 7 days
      const firstEventDate = eventDates[0];
      const lastEventDate = endDate;
      const allPossibleDates = [];
      let currentDate = firstEventDate;
      while (!isAfter(currentDate, lastEventDate)) {
        allPossibleDates.push(currentDate);
        currentDate = addDays(currentDate, 7);
      }

      const exDates = allPossibleDates.filter(
        (date) => !eventDates.some((d) => isSameDay(d, date))
      );

      // Build event start and end times
      const startPeriodInfo = periodsData.find(
        (p) => p["Period"] === timetableData[startPeriodIndex].periodName
      );
      const endPeriodInfo = periodsData.find(
        (p) => p["Period"] === timetableData[endPeriodIndex].periodName
      );

      const startTimeStr = startPeriodInfo["Start"];
      const endTimeStr = endPeriodInfo["End"];

      const firstEventDateTime = parse(
        `${format(firstEventDate, "yyyy-MM-dd")}T${startTimeStr}`,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      );

      const startTimeFormatted = format(
        firstEventDateTime,
        "yyyyMMdd'T'HHmmss"
      );

      const endEventDateTime = parse(
        `${format(firstEventDate, "yyyy-MM-dd")}T${endTimeStr}`,
        "yyyy-MM-dd'T'HH:mm",
        new Date()
      );

      const endTimeFormatted = format(endEventDateTime, "yyyyMMdd'T'HHmmss");

      // Build RRULE string
      const untilFormatted = format(endDate, "yyyyMMdd'T'HHmmss'Z'");

      const recurrenceRule = `FREQ=WEEKLY;INTERVAL=1;UNTIL=${untilFormatted}`;

      // Build EXDATE strings
      const exDateStrings = exDates
        .map((date) => {
          const exDateTime = parse(
            `${format(date, "yyyy-MM-dd")}T${startTimeStr}`,
            "yyyy-MM-dd'T'HH:mm",
            new Date()
          );
          return format(exDateTime, "yyyyMMdd'T'HHmmss");
        })
        .join(",");

      // Generate UID
      const uid = `${cellData.eventName}-${startTimeFormatted}@yourdomain.com`;

      // Build event summary and categories
      let eventSummary = cellData.eventName;
      const categories = ["[TIMETABLE]"];

      // Check for homework in any of the periods
      let homeworkSet = false;
      for (let i = startPeriodIndex; i <= endPeriodIndex; i++) {
        if (timetableData[i].dayData[dayIndex].homework) {
          homeworkSet = true;
          break;
        }
      }
      if (homeworkSet) {
        eventSummary += " [HWK]";
        categories.push("[HWK]");
      }

      let periodsRange = `${timetableData[startPeriodIndex].periodName}`;
      if (startPeriodIndex !== endPeriodIndex) {
        periodsRange += `-${timetableData[endPeriodIndex].periodName}`;
      }

      const periodDesc = `[P${periodsRange}/D${dayNumber}]`;
      categories.push(periodDesc);
      eventSummary += " " + periodDesc;

      // Build the VEVENT string
      let vevent = "";
      vevent += "BEGIN:VEVENT\r\n";
      vevent += `UID:${uid}\r\n`;
      vevent += `SUMMARY:${escapeICalText(eventSummary)}\r\n`;
      vevent += `DTSTART;TZID=${timeZone}:${startTimeFormatted}\r\n`;
      vevent += `DTEND;TZID=${timeZone}:${endTimeFormatted}\r\n`;
      vevent += `RRULE:${recurrenceRule}\r\n`;

      if (exDateStrings) {
        vevent += `EXDATE;TZID=${timeZone}:${exDateStrings}\r\n`;
      }

      vevent += `CATEGORIES:${categories.join(",")}\r\n`;
      vevent += `LOCATION:${escapeICalText(cellData.eventLocation)}\r\n`;
      vevent += `DESCRIPTION:${escapeICalText(eventSummary)}\r\n`;
      vevent += "STATUS:CONFIRMED\r\n";
      vevent += "SEQUENCE:0\r\n";
      vevent += "CLASS:PUBLIC\r\n";
      vevent += "TRANSP:OPAQUE\r\n";
      vevent += "END:VEVENT\r\n";

      // Add the event to the iCal content
      icalContent += vevent;

      // Move to the next period after the merged ones
      periodIndex = endPeriodIndex;
    }
  }

  // Close the VCALENDAR
  icalContent += "END:VCALENDAR\r\n";

  // Save the iCal content as a file
  const blob = new Blob([icalContent], { type: "text/calendar;charset=utf-8" });
  saveAs(blob, "timetable.ics");
};

// Function to generate VTIMEZONE component
function generateVTimezoneComponent(timeZone) {
  // For common time zones, you can include predefined VTIMEZONE components.
  // For Europe/London, here's a simplified VTIMEZONE component.
  return (
    "BEGIN:VTIMEZONE\r\n" +
    "TZID:Europe/London\r\n" +
    "BEGIN:DAYLIGHT\r\n" +
    "TZOFFSETFROM:+0000\r\n" +
    "TZOFFSETTO:+0100\r\n" +
    "TZNAME:BST\r\n" +
    "DTSTART:19700329T010000\r\n" +
    "RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU\r\n" +
    "END:DAYLIGHT\r\n" +
    "BEGIN:STANDARD\r\n" +
    "TZOFFSETFROM:+0100\r\n" +
    "TZOFFSETTO:+0000\r\n" +
    "TZNAME:GMT\r\n" +
    "DTSTART:19701025T020000\r\n" +
    "RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU\r\n" +
    "END:STANDARD\r\n" +
    "END:VTIMEZONE\r\n"
  );
}

// Function to escape special characters in iCal text
function escapeICalText(text) {
  return text
    .replace(/\\/g, "\\\\")
    .replace(/;/g, "\\;")
    .replace(/,/g, "\\,")
    .replace(/\n/g, "\\n");
}
